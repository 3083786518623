<template>
    <router-link :to="href">
        <div class="card post no-shadow hoverable">
            <div class="card-image square-image-container">
                <img class="image-fit" :src="post.banner.path">
            </div>
            <div class="card-content">
                <span class="card-title fontKufi2 black-text">{{ post.title }}</span>
                <p class="truncate grey-text">
                    {{ post.content }}
                </p>
            </div>

        </div>
    </router-link>
</template>
<script>
export default {
    props: {
        post: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            href: 'noticias/' + this.post.slug,
        }
    },
    computed: {
        first: function() {
            console.log(this.key);
            return this.key == 0;
        }
    }
}
</script>
