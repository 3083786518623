<template>
    <div class="default-layout" style="margin: 0; padding: 0">
        <div class="navbar-fixed">
            <nav class="white">
                <div class="nav-wrapper grey-text">
                    <a href="#" data-target="slide-out" class="sidenav-trigger grey-text" style="display: block;"><img src="img/logo-icon.jpg" style="height: 100%"></a>
                    <router-link to="/">
                        <a class="fontKufi2 black-text flow-text">
                            <b>Silvana N. Rodriguez</b>
                        </a>
                    </router-link>
                    <ul id="nav-mobile" class="right">
                        <li><a href="tel:+34971385168" class="grey-text text-darken-2">
                            <i class="material-icons no-margin">call</i>
                        </a></li>
                        <li><a target="_blank" class="grey-text text-darken-2" href="https://www.google.com/maps/place/Silvana+Natalia+Rodriguez/@40.0028911,3.8401727,15z/data=!4m2!3m1!1s0x0:0x432b423176a17172?sa=X&ved=2ahUKEwilp6Wl3KzjAhXQA2MBHbQ2BnYQ_BIwDnoECA0QCA">
                            <i class="material-icons no-margin">map</i>
                        </a></li>
                    </ul>
                </div>
            </nav>
        </div>
        <ul id="slide-out" class="sidenav">
            <li>
                <router-link to="/">
                    <a>Inicio</a>
                </router-link>
                <router-link to="noticias">
                    <a>Noticias</a>
                </router-link>
            </li>
        </ul>
        <!-- <nav-component></nav-component>
        <nav-mobile-component></nav-mobile-component> -->
        <div id="content">
            <slot/>
        </div>

        <footer class="page-footer grey darken-4">
            <div class="container">
                <div class="row">
                    <div class="col s12 m4">
                        <p class="flow-text fontKufi2 no-margin">Contacto</p>
                        <ul class="margin-5">
                            <li>
                                <span>Teléfono</span>
                                <a href="tel:+34971385168">971 38 51 68</a>
                            </li>
                            <li>
                                <span>E-mail</span>
                                <a href="mailto:abogada@silvananrodriguez">abogada@silvananrodriguez</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col s12 m4">
                        <p class="flow-text fontKufi2 no-margin">Dirección</p>
                        <a href="https://www.google.com/maps/place/Silvana+Natalia+Rodriguez/@40.0028911,3.8401727,15z/data=!4m2!3m1!1s0x0:0x432b423176a17172?sa=X&ved=2ahUKEwilp6Wl3KzjAhXQA2MBHbQ2BnYQ_BIwDnoECA0QCA" target="_blank">
                            <ul class="margin-5">
                                <li>Carrer de Curniola, 24</li>
                                <li>07760 Ciutadella de Menorca</li>
                                <li>Illes Balears</li>
                            </ul>
                        </a>
                    </div>
                    <div class="col s12 m4">
                        <p class="flow-text fontKufi2 no-margin">Horario</p>
                        <ul class="margin-5">
                            <li>
                                <span>De Lunes a Viernes: 9:00 – 13:30</span>
                            </li>
                            <li>
                                <span>Sábados y Domingos: Cerrado</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer-copyright">
                <div class="container">
                    © {{ new Date().getFullYear() }} Silvana Natalia Rodriguez
                    <ul class="list-inline right">
                        <li class="list-inline-item" v-for="(social, i) in socialMedia" :key="i">
                            <a :href="social.href">
                                <font-awesome-icon :icon="social.icon" size="2x"></font-awesome-icon>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            socialMedia: [
                {
                    icon: ['fab', 'facebook'],
                    href: '/'
                },
                {
                    icon: ['fab', 'instagram'],
                    href: '/'
                },
                {
                    icon: ['fab', 'twitter'],
                    href: '/'
                },
                {
                    icon: ['fab', 'linkedin'],
                    href: '/'
                },
            ]
        }
    }
}
</script>
