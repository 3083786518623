import axios from '@/config/axios.js'

export default {

    index(page = null) {
        return axios.get('posts' + (page ? `?page=${page}` : ''))
    },
    page(number) {
        return axios.get(`posts?page=${number}`);
    },

    store(data) {
        return axios.post('posts', data)
    },
    show(slug) {
        return axios.get(`posts/${slug}`)
    },
    update(slug, data) {
        return axios.put(`posts/${slug}`, data)
    },
    destroy(slug) {
        return axios.delete(`posts/${slug}`)
    }

}
