<template>
    <div class="post" style="min-height: 70vh">
        <div class="row" style="max-height: 500px; overflow: hidden;">
            <img
                class="image responsive-img"
                style="width: 100%; cursor: pointer;"
                :style="{
                    display: i > 0 ? 'none' : 'block'
                }"
                v-for="(image, i) in images"
                :src="image"
                :key="i"
                @click="index = i"
                >
            <vue-gallery-slideshow
                :images="images"
                :index="index"
                @close="index = null">
            </vue-gallery-slideshow>
        </div>
        <div class="row container text-center">
            <h3 class="fontKufi">{{ post.title }}</h3>
        </div>
        <div class="row container" style="white-space: pre-wrap" v-html="post.content"></div>
    </div>
</template>
<script>
import Post from '@/api/post.js'
import VueGallerySlideshow from 'vue-gallery-slideshow'
export default {
    data() {
        return {
            post: null,
            images: null,
            index: null,
        }
    },
    components: { VueGallerySlideshow },
    methods: {
        getIndex(imageSelected){
            this.post.images.forEach((image, index) => {
                if(image.id === imageSelected.id) {
                    return index;
                }
            });
        },
        setData(post) {
            this.post = post;
            this.images = this.post.images.map(({ path }) => path);
        }
    },
    /* beforeCreate() {
        return Post.show(this.$route.params.slug)
                .then((res) => res.data)
                .then((post) => post.data)
                .then((post) => {
                    //next((vm) => vm.setData(err, post));
                    this.post = post;
                    this.images = this.post.images.map(({ path }) => path);
                });
    }, */
    beforeRouteEnter(to, from, next) {
        Post.show(to.params.slug)
            .then((res) => res.data)
            .then((post) => post.data)
            .then((post) => {
                next((vm) => vm.setData(post));
                /* this.post = post;
                this.images = this.post.images.map(({ path }) => path); */
            });
    },
    beforeRouteUpdate (to, from, next) {
        this.post = null
        Post.show(to.params.slug)
            .then((res) => res.data)
            .then((post) => post.data)
            .then((post) => {
                this.setData(post)
                next();
            });
    },
}
</script>
