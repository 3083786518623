//Imports
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify'
import VeeValidate from 'vee-validate'
import App from '@/App'
import routes from '@/routes.js'

// materializeCss
import 'materialize-css/dist/css/materialize.css'
import 'materialize-css/dist/js/materialize.js'

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

import { dom } from '@fortawesome/fontawesome-svg-core'

dom.watch()

library.add(faUserSecret, faFacebook, faTwitter, faInstagram, faLinkedin)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

import DefaultLayout from '@/components/Layouts/DefaultLayout'

Vue.component('default-layout', DefaultLayout);

//Load Plugins
Vue.use(VueRouter)
Vue.use(Vuetify)
Vue.use(VeeValidate, {
    inject: false
})

//Router configuration
const router = new VueRouter({
    mode: 'history',
    routes
})

export const vm = new Vue({
    el: '#app',
    render: h => h(App),
    router
});
