<template>
    <div class="post-card-list">
        <div class="row grid-container l-one-third m-one-half s-one">
            <div class="grid-item margin-10 no-padding z-depth-1-half" v-for="(post, index) in posts" :key="index">
                <post-card
                :post="post"
                ></post-card>
            </div>
        </div>
        <div class="row center">
            <div class="center-block" style="max-width: 100%">
                <paginate
                :page-count="meta.last_page"
                :click-handler="fetch"
                :prev-text="'<'"
                :next-text="'>'"

                :container-class="'pagination'"
                ></paginate>
            </div>
        </div>

    </div>
</template>
<script>
import PostCard from '@/components/Models/Posts/PostCard'
import Paginate from 'vuejs-paginate'
import Post from '@/api/post.js'
export default {
    components: { PostCard, Paginate},
    data() {
        return {
            posts: [],
            links: {},
            meta: {}
        }
    },
    methods: {
        fetch(page = null) {
            Post.index(page)
            .then((res) => res.data)
            .then(({ data, links, meta }) => {
                this.posts = data;
                this.links = links;
                this.meta = meta;
            });
        }
    },
    created() {
        this.fetch();
    }
}
</script>

