<template>
    <div class="welcome">
        <div class="slider width-limiter limit-1920">
            <ul class="slides">
                <li>
                    <img src="img/slide-1-wide.jpg" alt="slide-1"/>
                    <div class="caption center-align valign-wrapper" style="height: 80%; top: 10%;">
                        <img src="img/logo-medium.jpg" style="height: 100%; background-size: contain; background-repeat: no-repeat"/>
                    </div>
                </li>
            </ul>
        </div>
        <div class="section">
            <div class="row width-limiter limit-1440">
                <div class="col s12 l10 offset-l1">
                    <div class="row no-margin">
                        <div class="row no-margin valign-wrapper">
                            <div class="col s12 l6">
                                <h3 class="text-uppercase fontKufi">Silvana N. Rodriguez</h3>
                                <h5 class="grey-text fontKufi2">Abogada en Ciutadella de Menorca</h5>
                                <p class="text-justify">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel porta risus. Curabitur condimentum scelerisque est, nec finibus eros consectetur interdum. Curabitur vestibulum arcu tortor, quis egestas turpis molestie nec. Aenean efficitur justo ac nulla consequat, non commodo mi ultrices. Morbi porta lacus a lacus consectetur finibus sed nec metus. Quisque efficitur at libero posuere maximus.
                                </p>
                            </div>
                            <div class="col s12 l6">
                                <div class="card no-shadow">
                                    <div class="card-image">
                                        <img src="img/silvana.jpg" alt="silvana">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row width-limiter limit-1440">
                <div class="col s12 l10 offset-l1">
                    <h3 class="text-uppercase center fontKufi">Titulación y experiencia</h3>
                    <div class="row no-margin" style="border: 2px solid">
                        <div class="col s12">
                            <ul>
                                <li><h6><b>· Lorem ipsum</b> dolor sit amet, consectetur adipiscing elit.</h6></li>
                                <li><h6><b>· Nam accumsan erat</b> non ornare condimentum.</h6></li>
                                <li><h6><b>· Aliquam tristique</b> velit quis libero pellentesque, vestibulum ultricies ante pulvinar.</h6></li>
                                <li><h6><b>· Praesent lobortis odio et magna condimentum,</b> at interdum purus ultricies.</h6></li>
                                <li><h6><b>· Aenean vel sapien posuere,</b> interdum eros ac, mollis ligula.</h6></li>
                                <li><h6><b>· Fusce rhoncus ligula</b> sed magna vestibulum, vel elementum tellus dignissim.</h6></li>
                                <li><h6><b>· In non nibh</b> ac urna maximus ornare.</h6></li>
                                <li><h6><b>· Ut vitae tortor lobortis,</b> tristique augue laoreet, eleifend neque.</h6></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row width-limiter limit-1920">
                <div class="col s12 l10 offset-l1">
                    <div class="row no-margin">
                        <h3 class="text-uppercase center fontKufi">Noticias</h3>
                        <div class="col s12">
                            <post-card-list></post-card-list>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row width-limiter limit-1920">
                <div class="col s12 l10 offset-l1">
                    <h3 class="text-uppercase center fontKufi">Contacto</h3>
                    <div class="row no-margin">
                        <div class="col s12 m6 right-align" style="border-right: 3px solid">
                            <p class="flow-text fontKufi2" style="font-size: xx-large"><a target="_blank" href="https://www.google.com/maps/place/Silvana+Natalia+Rodriguez/@40.0028911,3.8401727,15z/data=!4m2!3m1!1s0x0:0x432b423176a17172?sa=X&ved=2ahUKEwilp6Wl3KzjAhXQA2MBHbQ2BnYQ_BIwDnoECA0QCA" class="black-text">C/Curniola nº 24<br>07760 Ciutadella de Menorca<br>Illes Balears - España</a></p>
                        </div>
                        <div class="col s12 m6 left-align">
                            <p class="flow-text fontKufi2" style="font-size: xx-large">
                                <a class="black-text" href="tel:+34971385168">Tel. +34 971 38 51 68</a><br>
                                <a class="black-text" href="tel:618639776">Móvil 618 63 97 76</a><br>
                                <a class="black-text" href="mailto:abogada@silvananrodriguez.com">abogada@silvananrodriguez.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row width-limiter limit-1920">
                <div class="col s12 l10 offset-l1">
                    <div class="divider margin-40"></div>
                    <div class="row">
                        <h4 class="center grey-text fontKufi2">Nuestra oficina</h4>
                    </div>
                    <div class="row no-margin">
                        <div class="col s12 m6 l3">
                            <img class="responsive-img" src="img/office-images/office-1.jpeg"/>
                        </div>
                        <div class="col s12 m6 l3">
                            <img class="responsive-img" src="img/office-images/office-2.jpeg"/>
                        </div>
                        <div class="col s12 m6 l3">
                            <img class="responsive-img" src="img/office-images/office-3.jpeg"/>
                        </div>
                        <div class="col s12 m6 l3">
                            <img class="responsive-img" src="img/office-images/office-4.jpeg"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import PostCardList from '@/components/Models/Posts/PostCardList'
    import PostCard from '@/components/Models/Posts/PostCard'
    import Post from '@/api/post.js'
    export default {
        components: { PostCardList, PostCard },
        data() {
            return {
                post: null
            }
        },
        mounted(){
            let elems = document.querySelectorAll('.slider');
            M.Slider.init(elems, {height: 500, indicators: false});
        },
        methods: {
            setData(post) {
                this.post = post;
            }
        },
        beforeRouteEnter(to, from, next) {
            Post.index(1)
                .then((res) => res.data)
                .then((post) => post.data[0])
                .then((post) => {
                    next((vm) => vm.setData(post));
                    /* this.post = post;
                    this.images = this.post.images.map(({ path }) => path); */
                });
        },
        beforeRouteUpdate (to, from, next) {
            this.post = null
            Post.index(1)
                .then((res) => res.data)
                .then((post) => post.data[0])
                .then((post) => {
                    this.setData(post)
                    next();
                });
        },

    }
</script>
